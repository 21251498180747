<template>
  <div>
    <h1>当前版本v2.0.5</h1>
    <h2>
      历史更新记录
    </h2>
    <div>
      <div>
        <h3>
          v2.0.5
        </h3>
        <p>2022年8月11日</p>
        <p>CI-CD自动部署</p>
      </div>
      <div>
        <h3>
          v2.0.4
        </h3>
        <p>2022年8月2日</p>
        <p>删除使用说明</p>
      </div>
      <div>
        <h3>
          v2.0.3
        </h3>
        <p>2022年7月29日</p>
        <p>添加使用说明</p>
      </div>
      <div>
        <h3>
          v2.0.2
        </h3>
        <p>2022年7月21日</p>
        <p>返回首页、退出</p>
      </div>
      <div>
        <h3>
          v2.0.1
        </h3>
        <p>2022年7月1日</p>
        <p>样式调整</p>
      </div>
      <div>
        <h3>
          v2.0.0
        </h3>
        <p>2022年4月14日</p>
        <p>1 新增首页仪表盘</p>
        <p>2 大量样式调整</p>
      </div>
      <div>
        <h3>
          v1.0.1
        </h3>
        <p>2021年10月19日</p>
        <p>修复接口数据获取问题</p>
      </div>
      <div>
        <h3>
          v1.0.0
        </h3>
        <p>2021年10月19日</p>
        <p>网站上线</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
